import React, {FC, PropsWithChildren, useCallback, useEffect} from 'react';
import classNames from 'classnames';
import {toast, ToastContainer} from 'react-toastify';

export interface ToastProps {
  textBody: string;
  iconName?: string;
  showAlert?: boolean;
  autoClose?: false | number;
}

const ToastAlert: FC<PropsWithChildren<ToastProps>> = ({
  textBody,
  iconName,
  showAlert,
  autoClose,
}) => {
  const toastElement = useCallback(
    () => (
      <div className={classNames('toast-container')}>
        <div>
          <i
            className={classNames(
              'icon',
              'toast-icon',
              `icon ${iconName || 'icon-circle-checkmark'}`,
            )}
          />
        </div>
        <div>
          <span>{textBody}</span>
        </div>
      </div>
    ),
    [iconName, textBody],
  );
  useEffect(() => {
    if (showAlert) toast(toastElement, {autoClose});
  }, [showAlert, autoClose, toastElement]);

  return <ToastContainer />;
};

export default ToastAlert;
